<template>
    <router-view />
</template>

<style lang="scss">
* {
    font-family: 'Mulish', sans-serif !important;
}
input, button {
    &:focus {
        box-shadow: none !important;
    }
}
.bg-base {
    background: linear-gradient(-180deg, rgba(98,142,129,1) 0%, rgba(32,72,80,1) 100%) !important;
}
.fs-18px {
    font-size: 18px;
}
.btn-select-sunneva {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4) !important;
    color: #fff !important;
    border: none !important;
    text-align: left;
    &::after {
        position: absolute;
        top: 20px;
        right: 15px;
        border-right: .35em solid transparent;
        border-bottom: .35em solid;
        border-left: .35em solid transparent;
    }
}
.dropdown-sunneva {
    padding: 0;
    background: rgba(0,0,0,0.4);
    border: none;
    overflow: hidden;
    .dropdown-item {
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        &:hover, &:focus, &:active {
            background: linear-gradient(-180deg, rgba(98,142,129,1) 0%, rgba(32,72,80,1) 100%) !important;
        }
    }
}
.slide-origin {
    .splide__arrow--prev {
        left: -3rem;
    }
    .splide__arrow--next {
        right: -3rem;
    }
}
.slide-origin, .slide-mobile {
    .splide__track {
        box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12),0 5px 5px -3px rgba(0,0,0,0.2);
        border: 5px solid #fff;
        border-radius: 4px;
        background-image: url('~@/assets/logo.png');
        background-repeat: no-repeat;
        background-position: center;
    }
}
.slide-mobile {
    .slide-item {
        img {
            object-fit: cover;
        }
    }
}
.img-preview-mb {
    position: absolute;
    z-index: 4000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.9);

    .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        border: 1px solid rgba(0,0,0,1);
        border-radius: 50%;
        padding: 5px;
        background-size: 10px 10px;
        outline: 0;
        opacity: 1;
    }
}
.cursor {
    cursor: pointer;
}
</style>
