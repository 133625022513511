import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/components/index.vue'),
        meta: {
            title: 'Trang Chủ',
        },
        children: [
            {
                path: 'flat/:id',
                name: 'flat-id',
                component: () => import('@/views/flat/index.vue'),
                meta: {
                    title: 'Mặt Bằng',
                }
            },
            {
                path: 'maps',
                name: 'maps',
                component: () => import('@/views/maps/index.vue'),
                meta: {
                    title: 'Bản Đồ',
                }
            },
            {
                path: 'info',
                name: 'info',
                component: () => import('@/views/info.vue'),
                meta: {
                    title: 'Thông tin dự án',
                }
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/404.vue'),
        meta: {
            title: 'Not Found',
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
