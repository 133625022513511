import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createDeviceDetector } from 'next-vue-device-detector'
import VueSplide from '@splidejs/vue-splide'

import '@splidejs/vue-splide/css'

const app = createApp(App);
export const device = createDeviceDetector();

app.mixin({
    methods: {
        urlImage(image) {
            if(image.slice(0, 1) == '/'){
                image = image.slice(1);
            }
            return process.env.VUE_APP_BASE_ASSETS + image;
        }
    }
})

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAGMGljOx3xuxkBeIETHb-SyCf4gQyjxRY',
    },
})

router.beforeEach((to, next) => {
    document.title = `${ process.env.VUE_APP_TITLE } - ${to.meta.title}`
})

require('@/config/axios');

app.use(store)
    .use(router)
    .use(device)
    .use(VueSplide)
    .mount('#client')
